import {Component, Inject, OnInit} from '@angular/core';
import {AppSettings, SETTINGS} from '~/models';
import {Router} from '@angular/router';
import {OidcService} from '~/services';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    standalone: true,
})
export class LoginComponent implements OnInit {
  constructor(
    @Inject(SETTINGS) public settings: AppSettings,
    public router: Router,
    public oidcService: OidcService
  ) {
  }
  connectAvevaDataHub() {
    this.oidcService.login();
  }

  ngOnInit() {
    this.oidcService.cleanLogin();
  }
}

<style>
  /* Add custom styles here */
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #F0F0F0; /* Adjust the background color as needed */
  }

  .card {
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    width: 350px; /* Adjust width as needed */
  }

  .logo {
    display: block;
    margin: 0 auto;
    height: auto;
  }

  .button-connect {
    border:0;
    width: 100%;
    padding-top: 5px;
    cursor: pointer;
    color: #4d5ee0;
  }
  .button-connect:hover {
    color: #4d5880;
  }

  /* Add more styles as needed */
</style>
<div class="text-white text-center" id="content">
  <div class="container-fluid" >
    <div class="login-container">
      <div class="card">
        <img class="logo" src="assets/img/EcoStruxure-Logo.svg" alt="Schneider Electric Logo">
        <div style="color:black;text-align: center;">Plant Plug-In - Computations</div>
        <div class="single-sign-in-notice" style="margin-bottom: 10px;background: rgba(0,0,0,.03);
                color: rgba(0,0,0,.54);
                padding: 10px 0;
                text-align: center;
                font-size: 10px;
                text-transform: uppercase;
                letter-spacing: 1px;"><span>CORPORATE SIGN-IN ENABLED</span></div>

        <button class="button-connect" (click)="connectAvevaDataHub()">
          <div>
            <img src="assets/img/datahub.ico" style="float:left; width:32px;" alt="Aveva Data Hub"/>
            <div style="align-items: center; padding-top: 7px;"><b>AVEVA Data Hub</b></div>
          </div>
        </button>

      </div>
    </div>
  </div>
</div>
